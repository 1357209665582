<template>
  <div>
    <CSpinner v-if="$apollo.queries.salesRegion.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="sales region"
        :name="itemName"
        :nid="salesRegion.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CNavbar
        type="light"
        class="navbar-partner"
    >
      <CNavItem
          :to="`/partners/vendor/${this.salesRegion.vendor.nid}`"
          class="nav-item-partner"
      >
        Vendor
      </CNavItem>
      <CNavItem
          :to="`/partners/vendor/${this.vendorNid}/sales-regions`"
          class="nav-item-partner"
      >
        Sales Regions
      </CNavItem>
      <CNavItem
          :to="`/partners/vendor/${this.vendorNid}/sales-offices`"
          class="nav-item-partner"
      >
        Sales Offices
      </CNavItem>
    </CNavbar>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: `/partners/vendor/${vendorNid}/sales-regions`})"
            />
          </CCol>
          <CCol sm="8">
            <h2>{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SalesRegionForm
            v-bind:sales-region-in="salesRegionEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: `/partners/vendor/${vendorNid}/sales-regions`})"
        />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import SalesRegionForm from "@/apps/partners/components/SalesRegionForm";
const clonedeep = require('lodash.clonedeep')

const querySalesRegion = gql`
query salesRegion($nid: ID!) {
  salesRegion(nid: $nid) {
    nid
    activePapp
    name
    vendor{
      nid
      shortName
      operatesIn {
        nid
        name
      }
    }
    contactGender
    contactFirstName
    contactLastName
    contactEmail
    handoverRegions{
      numericPostalCode
    }
    displayRegions{
      numericPostalCode
    }
    msHandoverRegions{
      numericPostalCode
    }
    msDisplayRegions{
      numericPostalCode
    }
    accountOwner{
      nid
      firstName
      lastName
    }
    leadFeedbackReportRecipients{
      nid
      email
    }
  }
}
`
const updateSalesRegion = gql`
  mutation updateSalesRegion($nid: ID!, $salesRegion: SalesRegionInput!){
    updateSalesRegion(nid: $nid, salesRegion: $salesRegion){
        nid
    }
  }
`
const deleteSalesRegion = gql`
  mutation deleteSalesRegion($nid: ID!){
    deleteSalesRegion(nid: $nid)
  }
`

export default {
  name: "SalesRegion",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    SalesRegionForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      salesRegion: {},
      salesRegionEdited: {},
      salesRegionSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.salesRegionSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.salesRegionSave;
      this.$apollo.mutate({
        mutation: updateSalesRegion,
        variables: {
          nid: nid,
          salesRegion: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.salesRegionEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/vendor/${this.vendorNid}/sales-regions`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.salesRegionEdited = clonedeep(this.$apolloData.data.salesRegion);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteSalesRegion,
        variables: {
          nid: this.salesRegion.nid
        }
      })
      .then(res => {
        this.$emit('delete-sales-region', this.salesRegion.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/vendor/${this.vendorNid}/sales-regions`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.salesRegionEdited.name
    },
    vendorNid(){
      return this.salesRegion? this.salesRegion.vendor.nid: null;
    }
  },
  apollo: {
    salesRegion: {
      query: querySalesRegion,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.sales_region_nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>
